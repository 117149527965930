import {createSlice} from '@reduxjs/toolkit';
import {defaultMeta} from '@utils/commonTypes';
import {ISuppliersState} from './types';

const initialState: ISuppliersState = {
    meta: defaultMeta,
    data: [],
    links: {},
};

const SuppliersSlice = createSlice({
    name: 'suppliers',
    initialState,
    reducers:{
        init: () => {}
    }
});

export default SuppliersSlice.reducer;
