import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';
import {FetchBaseQueryArgs} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {devBaseUrl, prodBaseUrl} from '../api';

interface BaseQueryParams {
    baseUrl: string;
    prepareHeaders: FetchBaseQueryArgs['prepareHeaders'],
}

export const baseQuery = ({baseUrl, prepareHeaders}: Partial<BaseQueryParams> = {}) => fetchBaseQuery({
    baseUrl: baseUrl || (process.env.NODE_ENV === 'development' ? devBaseUrl : prodBaseUrl),
    prepareHeaders: prepareHeaders || ((headers, {getState}) => {
        const {token: {token}} = (getState() as any).app || {token: {token: null}};
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');
        return headers;
    })
});
