import React from 'react';
import {IconProps} from './types';

export const Edit = ({width, height, color, ...props}: IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 16H17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M4 16H1V13L11.5 2.5C12.3284 1.67157 13.6716 1.67157 14.5 2.5C15.3284 3.32843 15.3284 4.67157 14.5 5.5L4 16Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
