import React, {useEffect, useMemo} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {logout} from '@store/app.slice';
import {Navigation, NavigationMobile} from '../components/Navigation';
import {ReactComponent as LogoFullIcon} from '../icons/LogoFull.svg';
import {ReactComponent as LogoCompactIcon} from '../icons/LogoCompact.svg';
import {Avatar} from '../components/Avatar';
import {avatarSizes} from '../components/Avatar/types';
//import {CartSwitcher} from '../components/CartSwitcher';
import {useAppDispatch, useAppSelector, useResizeWindow} from '../hooks';
import {useGetUserProfileQuery} from '../pages/Profile/Profile.api';
import styles from './layouts.module.scss';

export const Header = () => {
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {token} = useAppSelector(state => state.app);
    const {data, isError} = useGetUserProfileQuery({token: token});


    useEffect(() => {
        // todo разобраться почему не работает нормально
        // dispatch(logout({navigate}));
    }, [isError]);

    const renderAvatar = useMemo(() => <Avatar full_name={data?.full_name} size={avatarSizes.m}/>, [data]);

    return (
        <div>
            <div className={styles.desktop}>
                <NavLink to='/'>
                    <LogoFullIcon className='d-none d-bp768-block'/>
                    <LogoCompactIcon className='d-block d-bp768-none'/>
                </NavLink>
                <Navigation/>
                <div className={styles.desktop__rightBox}>
                    {/*<NavLink to={'/profile'} className={styles.desktop__link}>*/}
                    {/*    <Plus width={12} height={12} color={colors.black}/>*/}
                    {/*    Добавить товары*/}
                    {/*</NavLink>*/}
                    {/*<CartSwitcher/>*/}
                    {width > 998 ? renderAvatar : null}
                </div>
            </div>
            <>
                <NavigationMobile/>
            </>
        </div>
    );
};

