import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ArrowLeft} from '@icons/ArrowLeft';
import React, {PropsWithChildren} from 'react';
import {NavTab, NavTabs} from '@ui/NavTabs';
import defaultLogo from '../../assets/images/company-logo-default.png';
import {Button} from '../../fields';
import {ButtonType} from '../../fields/types';
import styles from './CompaniesEdit/CompanieEdit.module.scss';
import {useGetCompanyByIdQuery} from './Companies.api';

const CompanyLayout = ({children}: PropsWithChildren) => {
    const {company} = useParams();
    const isEditing = !!company;


    const {data: _companyData, isLoading, error} = useGetCompanyByIdQuery({id: company}, {skip: !company});

    const companyData: any = _companyData || {};

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <div className='d-flex justify-content-end align-items-center mt-2'>
                <Link to='/companies' className={styles.editButton}>
                    <ArrowLeft className={styles.editIcon} width={18} height={17} color='#BCBCCC'/>
                    Назад
                </Link>
            </div>
            <span className={styles.registrationDate}>{companyData.registered_at}</span>
        </div>
        <div className={styles.profileContainer}>
            <div className={styles.logoContainer}>
                <img src={defaultLogo} alt='Company Logo' className={styles.companyLogo}/>
                <div className={styles.companyInfo}>
                    <h2 className={styles.companyName}>{companyData.name}</h2>
                    <Button text={'Удалить фото'} viewType={ButtonType.light} type={'button'}/>
                </div>
            </div>
            {isEditing && (
                <Link to={`/companies/create?parent_company_id=${company}`}>
                    <Button text={'Создать филиал бренда'} viewType={ButtonType.outline} type={'button'}/>
                </Link>
            )}
        </div>
        <NavTabs>
            {companyData.id && <NavTab to='./edit'>
                Профиль
            </NavTab>}
            {!companyData.id && <NavTab to='.'>
                Профиль
            </NavTab>}
            <NavTab to='./info' className='click-through'>
                Информация о компании
            </NavTab>
            <NavTab to='./employees'>
                Сотрудники
            </NavTab>
            <NavTab to='./rests'>
                Остатки
            </NavTab>
        </NavTabs>
        {children}
    </div>;
};

export default CompanyLayout;
