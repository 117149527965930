import React, {lazy, Suspense, useEffect} from 'react';
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {alertTypes} from '@store/types';
import {useExpiresToken} from '@hooks/useExpiresToken';
import {useAppAlert, useAppSelector} from './hooks';
import {ErrorPage} from './pages/ErrorPage';
import {Redirect} from './pages/Redirect';
import {MainLayout} from './layouts';
import CompanyLayout from './pages/Companies/CompanyLayout';

const TestPage = lazy(() => import('./pages/TestPage'));
const HomePage = lazy(() => import('./pages/Home/Home'));
const LoginPage = lazy(() => import('./pages/Auth/LoginPage'));
const RegistrationPage = lazy(() => import('./pages/Auth/RegistrationPage'));
const RecoveryPasswordPage = lazy(() => import('./pages/Auth/RecoveryPasswordPage'));
const NewPasswordPage = lazy(() => import('./pages/Auth/NewPasswordPage'));
const TiresPage = lazy(() => import('./pages/Tires/TiresPage'));
const DisksPage = lazy(() => import('./pages/Disks/DisksPage'));
const ProfilePage = lazy(() => import('./pages/Profile/ProfilePage'));
const SuppliersPage = lazy(() => import('./pages/Suppliers/SuppliersPage'));
const FavoriteSuppliersPage = lazy(() => import('./pages/FavoriteSuppliers/FavoriteSuppliersPage'));
const CartPage = lazy(() => import('./pages/Cart/CartPage'));
const CompaniesPage = lazy(() => import('./pages/Companies/CompaniesPage'));
const CompaniesEditPage = lazy(() => import('./pages/Companies/CompaniesEdit/CompaniesEditPage'));
const UploadRestsPage = lazy(() => import('./pages/Companies/Rests/UploadRestsPage'));
const EmployeesPage = lazy(() => import('./pages/Companies/Employees/EmployeesPage'));

const App = () => {
    const {initAlert} = useAppAlert();
    const {errorReq, successReq} = useAppSelector(state => state.app);

    useEffect(() => {
        errorReq !== null && initAlert({
            type: alertTypes.ERROR,
            text: errorReq?.message ?? '',
        });
        successReq !== null && initAlert({
            type: alertTypes.SUCCESS,
            text: successReq?.message ?? '',
        });
    }, [errorReq, successReq]);

    return (
        <Routes>
            <Route path='/' element={<Suspense children={<Outlet/>}/>}>
                <Route path='/test' element={<TestPage/>}/>
                <Route path='/login' element={<LoginPage/>}/>
                <Route path='/registration' element={<RegistrationPage/>}/>
                <Route path='/recovery-password' element={<RecoveryPasswordPage/>}/>
                <Route path='/reset-password' element={<NewPasswordPage/>}/>
                <Route path='/' element={<Suspense children={<PrivateRoute/>}/>}>
                    <Route path={'/'} element={<HomePage/>}/>
                    <Route path='/tires' element={<TiresPage/>}/>
                    <Route path='/disks' element={<DisksPage/>}/>
                    <Route path='/profile' element={<ProfilePage/>}/>
                    <Route path='/companies' element={<MainLayout children={<Outlet/>}/>}>
                        <Route path='' element={<CompaniesPage/>}/>
                        <Route path=':company/' element={<CompanyLayout children={<Outlet/>}/>}>
                            <Route path='edit' element={<CompaniesEditPage/>}/>
                            <Route path='employees' element={<EmployeesPage/>}/>
                            <Route path='rests' element={<UploadRestsPage/>}/>
                        </Route>
                        <Route path='create' element={<CompanyLayout children={<CompaniesEditPage/>}/>}/>
                    </Route>
                    <Route path='*' element={<ErrorPage/>}/>
                    <Route path='/redirect' element={<Redirect/>}/>
                    <Route path='/suppliers' element={<SuppliersPage/>}/>
                    <Route path='/favorite-suppliers' element={<FavoriteSuppliersPage/>}/>
                    <Route path='/cart' element={<CartPage/>}/>
                </Route>
            </Route>
        </Routes>
    );
};

function PrivateRoute() {
    const location = useLocation();
    const navigation = useNavigate();
    const {not_expires} = useExpiresToken();
    const {token} = useAppSelector(state => state.app);

    useEffect(() => {
        const isOuterTransition: string = location.search;
        if (isOuterTransition !== '' && token) {
            navigation(isOuterTransition);
        }
    }, [token]);

    if (token && not_expires) {
        return <Outlet/>;
    } else {
        return <Navigate to={'/login'}/>;
    }
}

export default App;
