import {createAsyncThunk} from '@reduxjs/toolkit';
import {helperHandleError} from '../../helpers';
import {api} from '../../api';
import {IResponseSearch, IStateApp} from '../../store/types';
import {IQueryItem, ISort} from '../../helpers/types';
import {IReqData, IRequest} from '../../utils/commonTypes';
import {setStepsToTypes} from '../../helpers/setStepsToTypes';
import {cond} from '../../commonTypes';
import {ITire, ITiresRests} from './types';
import {clearTires, setFilterPayload} from './Tires.slice';


export const sendFilter = createAsyncThunk(
    'tires/sendFilter',
    async ({data, sort}: IRequest, {dispatch, getState, signal}) => {
        const {app} = getState() as { app: IStateApp };

        try {
            await dispatch(clearTires());

            const hasSeason = data.some(i => i.key === 'season');

            if (!hasSeason) {
                const now = new Date();
                const month = now.getMonth() + 1;
                const defaultSeason = month >= 2 && month <= 8 ? 'Лето' : 'Зима';

                data.push({
                    key: 'season',
                    payload: defaultSeason,
                    cond: cond.eq,
                    collection: 'tyres'
                });
            }

            await dispatch(setFilterPayload(data));

            const reqData: IReqData = {query: data};
            if (sort.key !== null) reqData.order_by = sort;


            const res = await api.post<IResponseSearch<ITire[]>>('/search/tyres', reqData, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }, signal
            });
            const result = await res.data;
            if (Object.values(data).some(i => i.group === 'rear')) {
                return {
                    data: setStepsToTypes(result.data) as ITire[],
                    meta: result.meta,
                    links: result.links
                };
            }
            return {
                data: result.data,
                meta: result.meta,
                links: result.links
            };
        } catch (e) {

            helperHandleError('sendFilter, tires', e, dispatch);
        }
    }
);

export const getPage = createAsyncThunk(
    'tires/getPage',
    async ({data, sort, url}: IRequest, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            await dispatch(clearTires());

            const reqData: IReqData = {query: data};
            if (sort.key !== null) reqData.order_by = sort;

            const res = await api.post<IResponseSearch<ITire[]>>(url, reqData, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            const result = await res.data;
            if (Object.values(data).some(i => i.group === 'rear')) {
                return {
                    data: setStepsToTypes(result.data) as ITire[],
                    meta: result.meta,
                    links: result.links
                };
            }
            return {
                data: result.data,
                meta: result.meta,
                links: result.links
            };
        } catch (e) {
            helperHandleError('getPage, tires', e, dispatch);
        }
    }
);

export const showMoreTires = createAsyncThunk(
    'tires/showMoreTires',
    async ({data, sort, url}: IRequest, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        try {

            const reqData: IReqData = {query: data};
            if (sort.key !== null) reqData.order_by = sort;

            const res = await api.post<IResponseSearch<ITire[]>>(url, reqData, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            const result = await res.data;
            if (Object.values(data).some(i => i.group === 'rear')) {
                return {
                    data: setStepsToTypes(result.data) as ITire[],
                    meta: result.meta,
                    links: result.links
                };
            }
            return {
                data: result.data,
                meta: result.meta,
                links: result.links
            };
        } catch (e) {
            helperHandleError('getPage, tires', e, dispatch);
        }
    }
);

export const getTiresRests = createAsyncThunk(
    'tires/getTiresRests',
    async ({data, product_id}: { data: IQueryItem[], product_id: string }, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            const res = await api.post<IResponseSearch<ITiresRests[]>>(`/product/${product_id}/rests`, {query: data}, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            return res.data;
        } catch (e) {
            helperHandleError('getPage, tires', e, dispatch);
        }
    }
);

