import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {baseQuery} from '@store/baseQuery';
import {devBaseUrl, prodBaseUrl} from '../../api';
import {IToken} from '../../store/types';
import {handleError, handleSuccess} from '../../store/app.slice';
import {Company} from './types';

interface ITokenProps {
    token: IToken | null;
}

interface IUpdateCompanyProps {
    id: string;
    body: Partial<Company>;
    token: IToken;
}

export const CompaniesApi = createApi({
    reducerPath: 'companiesApi',
    baseQuery: baseQuery(),
    tagTypes: ['Company'],
    endpoints: (build) => ({
        getUserCompanies: build.query<Company[], {}>({
            query: () => ({
                url: '/companies',
            }),
            transformResponse: (response: { companies: Company[] }) => response.companies,
        } ),

        getCompanyById: build.query<Company, { id: string;}>({
            query: ({ id }) => ({
                url: `/companies/${id}`,
            }),
            transformResponse: (response: { company: Company }) => response.company,
        }),

        detachFilial: build.mutation<{ message: string; filial: Company }, { companyId: string; filialId: string; token: IToken }>({
            query: ({ companyId, filialId }) => ({
                url: `/companies/${companyId}/detach-filial/${filialId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ companyId, filialId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(CompaniesApi.util.invalidateTags(['Company']));
                } catch (e: any) {
                    dispatch(handleError({ message: e.response.message, errors: {} }));
                }
            },
        }),
        updateCompanyProfile: build.mutation<Company, { id: string; body: Partial<Company>}>({
            query: ({ id, body }) => ({
                url: `/companies/${id}/edit`,
                method: 'PUT',
                body,
            }),
            transformResponse: (response: { company: Company }) => response.company,
            async onQueryStarted({ id, body }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        CompaniesApi.util.updateQueryData(
                            'getCompanyById',
                            { id },
                            () => data
                        )
                    );
                    dispatch(handleSuccess({ message: 'Данные компании успешно обновлены' }));
                } catch (e: any) {
                    dispatch(handleError({ message: e.response.message, errors: {} }));
                }
            },
        }),
        createCompany: build.mutation<Company, { body: Partial<Company> }>({
            query: ({ body, }) => ({
                url: '/companies',
                method: 'POST',
                body,
            }),
            transformResponse: (response: { company: Company }) => response.company,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(CompaniesApi.util.invalidateTags(['Company']));
                    dispatch(handleSuccess({ message: 'Компания успешно создана' }));
                } catch (e: any) {
                    dispatch(handleError({ message: e.response.message, errors: {} }));
                }
            },
        }),
    }),
});

export const {
    useGetUserCompaniesQuery,
    useDetachFilialMutation,
    useGetCompanyByIdQuery,
    useUpdateCompanyProfileMutation,
    useCreateCompanyMutation
} = CompaniesApi;
