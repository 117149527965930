import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import {Provider} from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import {store} from './store';
import App from './App';
import './index.css';
import './global.scss';
import {Alert} from './components/Alert';
import 'moment/locale/ru';

const container = document.getElementById('root')!;
const root = createRoot(container);
const persiststore = persistStore(store);


const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 3000,
    offset: '0px',
    transition: transitions.SCALE,
};


root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persiststore}>
            <BrowserRouter>
                <AlertProvider template={Alert} {
                    ...options
                }>
                    <App/>
                </AlertProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
