import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from '@store/baseQuery';

export const SuppliersApi = createApi({
    reducerPath: 'suppliersApi',
    baseQuery: baseQuery(),
    endpoints: (build) => ({
        searchSuppliers: build.mutation({
            query: ({body}) => ({
                body,
                method: 'POST',
                url: '/search/suppliers'
            })
        })
    }),
});

export const {
    useSearchSuppliersMutation
} = SuppliersApi;
