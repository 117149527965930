import {configureStore, combineReducers} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
//import logger from 'redux-logger';
import {ProfileApi} from '../pages/Profile/Profile.api';
import profile from '../pages/Profile/Profile.slice';
import tires from '../pages/Tires/Tires.slice';
import disks from '../pages/Disks/Disks.slice';
import suppliers from '../pages/Suppliers/Suppliers.slice';
import {TiresApi} from '../pages/Tires/Tires.api';
import {DisksApi} from '../pages/Disks/Disks.api';
import {CompaniesApi} from '../pages/Companies/Companies.api';
import {SuppliersApi} from '../pages/Suppliers/Suppliers.api';
import app from './app.slice';

const rootReducer = combineReducers({
    app,
    profile,
    [ProfileApi.reducerPath]: ProfileApi.reducer,
    [SuppliersApi.reducerPath]: SuppliersApi.reducer,
    [TiresApi.reducerPath]: TiresApi.reducer,
    [DisksApi.reducerPath]: DisksApi.reducer,
    [CompaniesApi.reducerPath]: CompaniesApi.reducer,
    tires,
    disks,
    suppliers
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [CompaniesApi.reducerPath, ProfileApi.reducerPath, SuppliersApi.reducerPath, 'tires', 'disks']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            // logger,
            CompaniesApi.middleware,
            ProfileApi.middleware
        )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
