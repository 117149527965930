import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from '@store/baseQuery';
import {IToken} from '@store/types';
import {handleError, handleSuccess} from '@store/app.slice';
import {reSaveCredential} from '../../helpers/reSaveCredential';
import {IProfile} from './types';

interface IGetProfileResponse {
    user: IProfile
}

interface ITokenProps {
    token: IToken | null
}

interface IUpdateProps<T> extends ITokenProps {
    body: T
}

export const ProfileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: baseQuery(),
    tagTypes: ['Profile'],
    endpoints: build => ({
        getUserProfile: build.query<IProfile, ITokenProps>({
            query: () => ({
                url: '/user',
            }),
            transformResponse: (response: IGetProfileResponse) => response.user,
        }),
        updateUserProfile: build.mutation<IProfile, IUpdateProps<IProfile>>({
            query: ({body}) => ({
                url: '/user',
                method: 'POST',
                body,
            }),
            transformResponse: (response: IGetProfileResponse) => response.user,
            async onQueryStarted({token, ...patch}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(
                        ProfileApi.util.updateQueryData(
                            'getUserProfile',
                            {token},
                            () => {
                                return data;
                            })
                    );
                    dispatch(handleSuccess({message: 'Профиль сохранён'}));
                } catch (e:any) {
                    dispatch(handleError({message: e.response.message, errors:{}}));
                }
            },
        }),
        updateAvatar: build.mutation<IProfile, { id: number, token: IToken }>({
            query: ({id}) => ({
                url: '/user/avatar',
                method: 'POST',
                body: {
                    avatar: id
                },
            }),
            transformResponse: (response: IGetProfileResponse) => response.user,
            async onQueryStarted({id, token, ...patch}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(
                        ProfileApi.util.updateQueryData(
                            'getUserProfile',
                            {token},
                            () => {
                                return data;
                            })
                    );
                } catch (e:any) {
                    dispatch(handleError({message: e.response.message, errors:{}}));
                }
            },
        }),
        deleteAvatar: build.mutation<IProfile, { token: IToken }>({
            query: () => ({
                url: '/user/avatar',
                method: 'DELETE',
            }),
            transformResponse: (response: IGetProfileResponse) => response.user,
            async onQueryStarted({token, ...patch}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(
                        ProfileApi.util.updateQueryData(
                            'getUserProfile',
                            {token},
                            () => {
                                return data;
                            })
                    );
                } catch (e:any) {
                    dispatch(handleError({message: e.response.message, errors:{}}));
                }
            },
        }),
        userContactsVerify: build.mutation<IProfile, { url: string, token: IToken, type: 'phone' | 'email' }>({
            query: ({url}) => ({
                url: url,
                method: 'GET',
            }),
            transformResponse: (response: IGetProfileResponse) => response.user,
            async onQueryStarted({url, token, type, ...patch}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;

                    await reSaveCredential({type, data});

                    dispatch(
                        ProfileApi.util.updateQueryData(
                            'getUserProfile',
                            {token},
                            () => {
                                return data;
                            })
                    );
                    dispatch(handleSuccess({message: 'Успешно верифицировано!'}));
                } catch (e:any) {
                    dispatch(handleError({message: e.response.message, errors:{}}));
                }
            },
        })
    }),
});

export const {
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useUpdateAvatarMutation,
    useDeleteAvatarMutation,
    useUserContactsVerifyMutation
} = ProfileApi;
