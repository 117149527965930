import React from 'react';
import {IconProps} from './types';

export const Lock = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 10H7C5.89543 10 5 10.8954 5 12V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V12C19 10.8954 18.1046 10 17 10Z"
                stroke="#8A89A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 9V8C8 5.79086 9.79086 4 12 4V4C14.2091 4 16 5.79086 16 8V9" stroke="#8A89A3" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16V14" stroke="#8A89A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};
