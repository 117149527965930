import {PropsWithChildren} from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';
import clsx from 'clsx';
import styles from './NavTabs.module.scss';

export const NavTabs = ({children}: PropsWithChildren) => {
    return <div className={clsx('d-flex', styles.navTabs)}>
        {children}
    </div>;
};

export const NavTab = ({className, ...props}: NavLinkProps) => {
    return <NavLink
        className={clsx(
            className,
            styles.navTab,
            'd-flex justify-content-center flex-grow-1 flex-shrink-0 flex-basis-0 flex-basis-0 text-t2'
        )}
        {...props}
    />;
};
